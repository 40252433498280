.table-pd {
  .table-pd2 {
    .session-card-pd {
      width: 25px;
    }
  }
}
.session-table {
  td {
    vertical-align: top;
    padding: 8px;
  }
  tr {
    max-width: 10px;
  }
}

.cardPadding {
  .css-1j5s3cl {
    margin-bottom: 10px;
  }
  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}
.card2 {
  .css-8145p1 {
    margin-bottom: 10px;
  }
  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}
.card3 {
  .css-189e0nv {
    margin-bottom: 10px;
  }
  .css-1ludl2-MuiStack-root {
    margin-top: 15px;
  }
}
.single-view-taps {
  .single-view-tab {
    .css-heg063-MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
    }
    .css-6d3pg0-MuiTabs-indicator {
      display: none !important;
    }
  }
  #simple-tabpanel-0,
  #simple-tabpanel-1 {
    flex: 1;
  }
  .css-wdsdfe-MuiButtonBase-root-MuiTab-root.Mui-selected {
    border: 1px solid #009688;
    border-radius: 10px;
    color: #009688 !important;
  }
  .css-19kzrtu {
    padding: 0;
    padding-right: 40px !important;
  }
}
@media screen and (min-width: 1200px) {
  #sidebar-inActive {
    transition: all ease-in-out 0.3s;
    width: 65px;
    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 65px;
    }
    .css-121b4uz-MuiListItemIcon-root {
      min-width: 48px;
    }
    .css-1y6sui9 {
      width: 65px !important;
    }
  }

  #sidebar-active {
    transition: all ease-in-out 0.3s;
    width: 280px;
    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 280px;
    }
  }
}
@media screen and (max-width: 1200px) {
  #main-body-sidebar-active {
    padding-left: 30px;
    padding-right: 30px;
  }
}
#main-body-sidebar-inActive {
  .css-1oqqzyl-MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: none;
    }
  }
}

.my-new-select .css-13cymwt-control {
  border-radius: 6px;
  min-height: 40px !important;
}
.my-new-select .css-1jqq78o-placeholder {
  color: #212b36;
}
.session-update-form .css-1ifcsjq-MuiFormLabel-root-MuiInputLabel-root {
  z-index: -1 !important;
}

.my-new-select .select2-selection__menu.css-1nmdiq5-menu {
  z-index: 99;
  position: absolute;
}
